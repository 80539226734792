
function LoginLink() {
    return (
        <div>
            <h1>LoginLink</h1>
        </div>
    );
}

export default LoginLink;

// EOF